/*
 * MAIN NET
 */

 export const CHAIN_ID = 0xa86a;
 export const CHAIN_ID_STRING = '0xa86a';
 export const CHAIN_NAME = 'Avalanche Network';
 export const RPC_URL = 'https://api.avax.network/ext/bc/C/rpc';
 export const CONTRACT_ADDRESS = '0xac87C3403aFF6229819595E469Dc76084a75d7A8';

/*
 * TEST NET (FUJI)
 */

//export const CHAIN_ID = 0xa869;
//export const CHAIN_ID_STRING = '0xa869';
//export const CHAIN_NAME = 'Avalanche Fuji Network';
//export const RPC_URL = 'https://api.avax-test.network/ext/bc/C/rpc';
//export const CONTRACT_ADDRESS = '0xf896cA00E2DC4471964DFDbA0584f41F6c06A074';
