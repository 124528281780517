import React, { useState } from 'react';
import styled from 'styled-components';
import { GoThreeBars } from 'react-icons/go';

import kalaoLogo from 'assets/Kalao_logo.svg';

const Nav = styled.nav`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 90px;
  padding: 0 30px;

  @media screen and (max-width: 550px) {
    padding: 0 20px;
  }
`;

const NavLogo = styled.img`
  height: 50px;
`;

const ListContainer = styled.ul`
  display: flex;

  @media screen and (max-width: 550px) {
    display: none;
  }
`;

const ListItem = styled.li`
  margin-left: 35px;
  font-size: 14px;
  letter-spacing: 0.5px;
`;

const ListItemLink = styled.a`
  color: #1d3561;
  border-bottom: 2px solid transparent;
  padding-bottom: 4px;

  &.active {
    border-bottom: 2px solid #c7c6c6;
  }
`;

const MobileIcon = styled(GoThreeBars)`
  display: none;
  cursor: pointer;
  height: 28px;
  width: 28px;
  color: #1d3561;

  @media screen and (max-width: 550px) {
    display: block;
  }
`;

const MobileMenuContainer = styled.ul`
  display: none;
  height: ${(props: { opened: boolean }) => (props.opened ? '150px' : '0')};
  padding: 0;
  position: fixed;
  top: 75px;
  left: 0;
  width: 100vw;
  background-color: #1d3561;
  transition: height 0.3s;
  z-index: 20;

  @media screen and (max-width: 550px) {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }
`;

const MobileMenuItem = styled.li`
  transition: height 0.4s;
  height: 30px;
  overflow: hidden;
  display: none;
  font-size: 14px;

  @media screen and (max-width: 550px) {
    display: flex;
    align-items: center;
    padding: 0 15px;
    height: ${(props: { opened: boolean }) => (props.opened ? '30px' : '0')};
    width: 100%;

    > a {
      letter-spacing: 0.5px;
      font-weight: 300;
    }
  }
`;

type MobileNavbarProps = {
  opened: boolean;
  onOpen: () => void;
  onClose: () => void;
};

const MobileNavbar: React.FC<MobileNavbarProps> = ({
  opened,
  onOpen,
  onClose,
}) => {
  return (
    <>
    </>
  );
};

const Navbar: React.FC = () => {
  const [open, setOpen] = useState(false);
  const onOpen = () => setOpen(true);
  const onClose = () => setOpen(false);
  return (
    <Nav>
      <a href='/'>
        <NavLogo src={kalaoLogo} />
      </a>
    </Nav>
  );
};

export default Navbar;
