import React from 'react';

import { Navbar } from 'components';
import { Claim } from 'Claim';

const App: React.FC = () => {
  return (
    <>
      <Navbar />
      <Claim />
    </>
  );
};

export default App;
